<template>
  <v-card class="statistic-card-vertical">
    <div class="d-flex align-center">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>

      <div class="ms-3">
        <span class="text-xs">
          {{ title }}
        </span>
        <p class="text-no-wrap mb-0">
          <v-progress-circular v-if="isLoading" indeterminate color="primary" size="26" />
          <span v-else class="text-xl font-weight-semibold text--primary">
            {{ statisticValue }}
          </span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    statisticValue: {
      type: [Number, String],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },

  setup() {},
}
</script>

<style lang="scss" scoped>
  .statistic-card-vertical {
    padding: 1.25rem;

    display: flex;
    height: 100%;
  }
</style>
